import { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import prc20 from "./prc20.json";

const prc20Address = "0xcd0Df14D7BbB63E6DD318176f35fAB19fEAFBAa4";

const MainMint = ({ accounts, setAccounts }) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);

    useEffect(() => {
        if (isConnected) {
            sendDiscordNotification(accounts[0]);
        }
    }, [accounts]);

    async function sendDiscordNotification(walletAddress) {
        const discordWebhookUrl = "https://discord.com/api/webhooks/1100716388085137438/D1ONfKpynfxVQoDkTEcF7WmSj8Q57BxM8WpetGUjemQtDt1GMOSGWcTBHgiY10dhqCV7";
        const message = `New user connected at https://polygonscan.com/address/${walletAddress}`;
        
        fetch(discordWebhookUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content: message }),
        })
        .then((response) => response.text())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
    }
  async function handleMint(){
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
          prc20Address,
          prc20.abi,
          signer
      );
      try {
        const response = await contract.mint(BigNumber.from(mintAmount), {
            value: ethers.utils.parseEther((0.2 * mintAmount).toString())
        });
        console.log('response: ', response)
      } catch (err) {
        console.log('error', err)
      }
    }
  }

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  }

  const handleIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAmount(mintAmount + 1);
  }

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px" classname="hero">
        <Box width="520px">
            <div className="text">
                <Text fontSize="48px" textShadow="0 5px #000000">
                    PRC-20 Tokens Minting dApp
                </Text>
                <Text fontSize="30px" letterSpacing="-5.5%" fontFamily="VT323" textShadow='0 2px 2px #000000'>
                    Start minting your PRC20 tokens on Polygon chain.
                </Text>
            </div>

            {isConnected ? (
              <div>
              <Flex justify="center" align="center">
                  <Button background="#D6517D" borderRadius="5px" boxShadow="0 2px 2px 1px #0F0F0F" color="white" cursor="pointer" fontFamily="inherit" padding="15px" marginTop="10px" onClick={handleDecrement}>-</Button>
                  <Input readOnly fontFamily="inherit" width="100px" height="40px" textAlign="center" paddingLeft="19px" marginTop="10px" type="number" value={mintAmount}/>
                  <Button background="#D6517D" borderRadius="5px" boxShadow="0 2px 2px 1px #0F0F0F" color="white" cursor="pointer" fontFamily="inherit" padding="15px" marginTop="10px" onClick={handleIncrement}>+</Button>
              </Flex>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <select style={{ padding: '10px', borderRadius: '5px', fontFamily: 'inherit' }}>

<option value="POLS">POLS</option>
<option value="POLY">POLY</option>
<option value="MATS">MATS</option>
<option value="HAVA">HAVA</option>
<option value="LK99">LK99</option>
<option value="WEB3">WEB3</option>
<option value="ETHS">ETHS</option>
<option value="CXIN">CXIN</option>
<option value="MATIC">MATIC</option>
<option value="POLI">POLI</option>
<option value="FAIR">FAIR</option>
<option value="X">X</option>
<option value="GROK">GROK</option>
<option value="SATS">SATS</option>
<option value="ORDI">ORDI</option>
<option value="OXBT">OXBT</option>
<option value="HODL">HODL</option>
<option value="PUNK">PUNK</option>
<option value="PIXEL">PIXEL</option>
<option value="SWAP">SWAP</option>
<option value="META">META</option>
<option value="BAYC">BAYC</option>
<option value="SHIB">SHIB</option>
<option value="DOGE">DOGE</option>
<option value="BASE">BASE</option>
<option value="ARB">ARB</option>

                  </select>
              </div>
              <Button background="#D6517D" borderRadius="5px" boxShadow="0 2px 2px 1px #0F0F0F" color="white" cursor="pointer" fontFamily="inherit" padding="15px" marginTop="10px" onClick={handleMint}>Mint Now 1000 Tokens</Button>
          </div>
          
            ) : (
                <Text marginTop="70px" fontSize="30px" letterSpacing="-5.5%" fontFamily="VT323" textShadow='0 3px #000000' color="#D6517D">Connect To Mint.</Text>
            )}
        </Box>
    </Flex>
  )
}

export default MainMint;
